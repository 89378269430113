import React, { useState, useEffect } from 'react';
import moment from "moment";
import { Table, Layout, Menu } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { answersService } from "../../services/AnswersService";
import ReactPaginate from 'react-paginate';
import '../../resources/main.scss'
const { Header, Content } = Layout;
const columns = [
    {
        title: 'Nume',
        dataIndex: 'first_name',
        key: 'name',
    },
    {
        title: 'Prenume',
        dataIndex: 'last_name',
        key: 'surname',
    },
    {
        title: 'Telefon',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Clasa',
        dataIndex: 'grade',
        key: 'grade',
    },
    {
        title: 'Optiuni facultate',
        dataIndex: 'faculty_options',
        key: 'faculty_options',
        render: (faculty_options) => faculty_options && faculty_options.join(', '),
    },
    {
        title: 'Alte optiuni facultate',
        dataIndex: 'other_faculty_options',
        key: 'other_faculty_options',
    },
    {
        title: 'Provocari',
        dataIndex: 'challenges',
        key: 'challenges',
    },
    {
        title: 'Alte provocari',
        dataIndex: 'other_challenges',
        key: 'other_challenges',
    },
    {
        title: 'Platforma informare',
        dataIndex: 'platform_preferences',
        key: 'platform_preferences',
    },
    {
        title: 'Alta platforma',
        dataIndex: 'other_platform_preferences',
        key: 'other_platform_preferences',
    },
    {
        title: 'Informare',
        dataIndex: 'information',
        key: 'information',
    },
    {
      title: 'Data completare',
      dataIndex: 'created_at',
      render: (created_at) => { console.log(created_at); return moment(created_at).format('YYYY-MM-DD'); }
    }
];

const FestivalAnswers = () => {
    const navigate = useNavigate();
    const [answers, setAnswers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        answersService.getSummerwellAnswers(currentPage)
            .then(response => {
                if (response.status === 401) {
                    navigate("/login");
                } else {
                    setAnswers(response.data.summerwell_applicants);
                    setPagination(response.data.pagination);
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    navigate("/login");
                } else {
                    console.error('Error fetching data:', error);
                }
            });
    }, [navigate]);

    const dataSource = Object.keys(answers).map((answer) => ({
        answers,
        ...answers[answer],
    }));

    const handlePageClick = (data) => {
        answersService.getSummerwellAnswers(data.selected + 1)
            .then(response => {
                if (response.status === 401) {
                    navigate("/login");
                } else {
                    setAnswers(response.data.summerwell_applicants);
                    setPagination(response.data.pagination);
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    navigate("/login");
                } else {
                    console.error('Error fetching data:', error);
                }
            });
    };

    return (
        <Layout className='standard'>
            <Header>
                <div className="logo" />
                <Menu theme="dark" mode="horizontal">
                    <Menu.Item key="1">
                        <Link to="/raspunsuri-program">Raspunsuri program</Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <Link to="/raspunsuri-summerwell">Raspunsuri chestionar Summerwell</Link>
                    </Menu.Item>
                </Menu>
            </Header>
            <Content style={{ padding: '0 50px', marginTop: 64 }}>
                <div style={{ padding: 24, minHeight: 280 }}>
                    <Table
                     dataSource={dataSource}
                      columns={columns}
                      rowKey="id"
                      pagination={false}
                      title={() => (
                        <div>
                            <h2>Răspunsuri Chestionar Summerwell</h2>
                        </div>
                    )}
                      />
                    {pagination && pagination.last_page && (
                      <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={pagination.last_page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                      />
                    )}
                </div>
            </Content>
        </Layout>
    );
};

export default FestivalAnswers;
