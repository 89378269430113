import React from 'react';
import './MobileMenu.scss';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'
class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMenu: false
        }
    }

    openMenu = () => {
        this.setState({
            activeMenu: true
        });
        document.body.classList.add('has-active-menu');
    };

    closeMenu = () => {
        this.setState({
            activeMenu: false
        });
        document.body.classList.remove('has-active-menu');
    }

    toggleSubmenu = (subMenuId) => {
        let menu = this.state.menu;
        menu.forEach(menuItem => {
            if (menuItem.id === subMenuId) {
                menuItem.open = !menuItem.open;
            }
        });
        this.setState({ menu });
    }

    render() {
        return (
            <div className="standard hamburger">
                <img
                    onClick={this.openMenu}
                    className="hamburger__icon"
                    src='/images/menu.png'
                    alt="hamburgerMenuLogo"
                />
                <ul
                    className={
                        'hamburger__links' + (this.state.activeMenu ? ' active' : '')
                    }
                >
                
                    <div className='hamburger__links-list'>
                        <img src="/images/logo-white.png" alt='logo' className='hamburger__logo'/>
                        <ScrollLink
                            className='hamburger__link'
                            to='despre'
                            smooth={true}
                            duration={500}
                            onClick={this.closeMenu}
                        >
                            <li className='hamburger__list-item'>Despre</li>
                        </ScrollLink>
                        <ScrollLink
                            className='hamburger__link'
                            to='ce-inseamna'
                            smooth={true}
                            duration={500}
                            onClick={this.closeMenu}
                        >
                            <li className='hamburger__list-item'>Ce înseamnă</li>
                        </ScrollLink>
                        <ScrollLink
                            className='hamburger__link'
                            to='skillup'
                            smooth={true}
                            duration={500}
                            onClick={this.closeMenu}
                        >
                            <li className='hamburger__list-item'>SkillUp</li>
                        </ScrollLink>
                        <ScrollLink
                            className='hamburger__link'
                            to='mentori'
                            smooth={true}
                            duration={500}
                            onClick={this.closeMenu}
                        >
                            <li className='hamburger__list-item'>Mentori</li>
                        </ScrollLink>
                        <ScrollLink
                            className='hamburger__link'
                            to='impact'
                            smooth={true}
                            duration={500}
                            onClick={this.closeMenu}
                        >
                            <li className='hamburger__list-item'>Impact social</li>
                        </ScrollLink>
                        <ScrollLink
                            className='hamburger__link'
                            to='parteneri'
                            smooth={true}
                            duration={500}
                            onClick={this.closeMenu}
                        >
                            <li className='hamburger__list-item'>Parteneri</li>
                        </ScrollLink>
                        <ScrollLink
                            className='hamburger__link'
                            to='intrebari-frecvente'
                            smooth={true}
                            duration={500}
                            onClick={this.closeMenu}
                        >
                            <li className='hamburger__list-item'>FAQ</li>
                        </ScrollLink>
                    </div>
                   
                </ul>
                <img
                    onClick={this.closeMenu}
                    className={
                        'hamburger__close-icon' + (this.state.activeMenu ? ' active' : '')
                    }
                    src="/images/close-icon.png"
                    alt="close Icon"
                />
            </div>
        )
    }
}

export default MobileMenu;