import axios from 'axios';
import { loginService } from './LoginService';

export const answersService={
    getProgramAnswers,
    getSummerwellAnswers
}
function getProgramAnswers( pageNumber) {
    let url = `${process.env.REACT_APP_API_URL}/api/program_applicants?page[number]=${pageNumber ? pageNumber : 1}`;
  
    const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] = loginService.getToken();
    }
  
    return axios(requestOptions);
  }
  function getSummerwellAnswers( pageNumber) {
    let url = `${process.env.REACT_APP_API_URL}/api/summerwell_applicants?page[number]=${pageNumber ? pageNumber : 1}`;
  
    const requestOptions = {
      method: 'GET',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }
    if (loginService.loggedIn()) {
      requestOptions.headers['Authorization'] = loginService.getToken();
    }
  
    return axios(requestOptions);
  }