import React, { useState, useEffect } from 'react';
import './Login.scss';
import '../../resources/main.scss'
import { Link, useNavigate } from 'react-router-dom'
import { loginService } from '../../services/LoginService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Input, Button } from 'antd';
import axios from 'axios';

const Login = () => {
    const [data, setData] = useState({
        email: '',
        password: ''
    })
    const [showPassword, setShowPassword] = useState(false);
    const [resetModalVisible, setResetModalVisible] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const navigate = useNavigate();
    const handleChange = event => {
        const { name, value } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }
    const handleResetModalOpen = () => {
        setResetModalVisible(true);
    };

    const handleResetModalClose = () => {
        setResetModalVisible(false);
    };

    const handleResetEmailChange = e => {
        setResetEmail(e.target.value);
    };

    const handleResetPassword = () => {
        loginService.forgotPassword(resetEmail).then(response=>{
            toast.success('Verifica email');

        })
        .catch((err)=>{
            toast.error('Email invalid');
        })
        setResetModalVisible(false);
    };
    const handleSubmit = event => {
        event.preventDefault();

        loginService
            .login(data)
            .then(response => {
                console.log(response.headers)
                loginService.setToken(response.headers.authorization);
                localStorage.setItem('userProfile', JSON.stringify(response.data));
                toast.success('Login successful!');
   
                  navigate('/raspunsuri-program');
                
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.error === 'Your account is not activated yet.') {
                        toast.error('Error');
                        return;
                    }
                }
                toast.error('Invalid email or password');
            });

    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const responseMessage = (response) => {
      loginService.googleAuthCallback(response.credential)
        .then(response => {
          loginService.setToken(response.headers.authorization);
          localStorage.setItem('userProfile', JSON.stringify(response.data));
          toast.success('Login successful!');
          
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.data.error) {
                    toast.error(error.response.data.error);
                    return;
                }
            }
            toast.error('Invalid email or password');
        });
    };

    useEffect(() => {
      /* global google */
      // if(window.google !== undefined){
      //   console.log(google);
      //   window.google.accounts.id.initialize({
      //     client_id: "217937645884-91odkitsarn1mpuafqqept08un58du2l.apps.googleusercontent.com",
      //     callback: responseMessage,
      //     cancel_on_tap_outside: false,
      //   });
      //
      //   window.google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      //     theme: "outline",
      //     size: "large",
      //   });
      //
      //   window.google.accounts.id.prompt();
      // }
    }, [])

    return (
        <div className='standard login'>
            <img src='/images/logo.png' alt='logo' className='login__logo' />
            <div className='login__top'></div>
            <div className='login__wrapper'>
                <div className='login__container'>
                    <h3 className='login__title'>Conectează-te la contul tău</h3>
                    <form className='login__form login__form' onSubmit={handleSubmit}>
                        <div className='login__field-box'>
                            <h4 className='login__label'>Email*</h4>
                            <input
                                className='login__field'
                                type="email"
                                name="email"
                                onChange={handleChange}
                                required
                                value={data.email} />
                        </div>
                        <div className='login__field-box login__password-input'>
                            <h4 className='login__label'>Parola*</h4>
                            <input
                                className='login__field'
                                type={showPassword ? "text" : "password"}
                                name="password"
                                onChange={handleChange}
                                required
                                value={data.password} />
                                  <img src={showPassword? '/images/eye-closed.png':'/images/eye.png'} alt='see password' className='login__eye-icon' onClick={togglePasswordVisibility}/>
                        </div>

                        <div className='login__account-box'>
                            <button type='submit' className='login__submit-button'><h5 className='login__button-writing'>Conectare</h5></button>
                        </div>
                    </form>
                </div>
                <div id="signInDiv"></div>
            </div>
           
        </div>
    );
};

export default Login;
