import React, { useState } from 'react';
import './FaqSection.scss';

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleFAQ = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="faq-item">
            <div className="faq-question" onClick={toggleFAQ}>
                <h4>{question}</h4>
                <img
                    src={isOpen ? '/images/minus.svg' : '/images/plus.svg'}
                    alt={isOpen ? 'up' : 'down'}
                    className="faq-icon"
                />
            </div>
            {isOpen && <div className="faq-answer"><p>{answer}</p></div>}
        </div>
    );
};

const FAQSection = ({ faqs }) => {
    return (
        <div className="faq-section">
            <div className='faq-wrapper'>
            {faqs.map((faq) => (
                <FAQItem key={faq.id} question={faq.question} answer={faq.answer} />
            ))}
            </div>
         
        </div>
    );
};

export default FAQSection;