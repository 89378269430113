import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Homepage from "./containers/Homepage/Homepage";
import Questionnaire from "./containers/Questionnaire/Questionnaire";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './containers/Login/Login'
import ProgramAnswers from "./containers/ProgramAnswers/ProgramAnswers";
import FestivalAnswers from "./containers/FestivalAnswers/FestivalAnswers";
import ProgramPage from "./containers/ProgramPage/ProgramPage";

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Routes>

            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/chestionar" element={<Questionnaire />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/raspunsuri-program" element={<ProgramAnswers />} />
            <Route exact path="/raspunsuri-summerwell" element={<FestivalAnswers />} />
            <Route exact path="/program" element={<ProgramPage />} />

          </Routes>
        </Router>
        <ToastContainer autoClose={1000}   position="top-center" />

      </Suspense>
    </div>
  );
}

export default App;
