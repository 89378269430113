import React, { useState, useEffect } from 'react';
import './ProgramPage.scss';
import '../../resources/main.scss';

const ProgramPage = () => {
    return (
        <div className='standard program'>
            <div className='program__header'>
                <img src='/images/logo.png' alt='logo' />
                <div className='program__contact'>
                    <h4><span>Contact: </span> hello@eduup.ro |  <a className='standard__link' href="tel:+40722280027">
                        +40722280027 
                    </a></h4>
                </div>
            </div>
            <div className='program__banner'>
                  <h2>SkillUp & Business</h2>
                  <div className='program__asus'>
                    <h3>by</h3>
                    <img src="/images/asus-logo.png" alt='asus'/>
                  </div>
            </div>
        </div>
    )
}

export default ProgramPage;