import React, { useState, useEffect } from 'react';
import { Table, Layout, Menu } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { answersService } from "../../services/AnswersService";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import '../../resources/main.scss'

const { Header, Content } = Layout;

const columns = [
    {
        title: 'Nume',
        dataIndex: 'first_name', // Changed to match API response
        key: 'first_name',
    },
    {
        title: 'Prenume',
        dataIndex: 'last_name', // Changed to match API response
        key: 'last_name',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Telefon',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Oras',
        dataIndex: 'city',
        key: 'city',
    },
    {
        title: 'Institutie',
        dataIndex: 'learning_institute', // Changed to match API response
        key: 'learning_institute',
    },
    {
        title: 'GDPR Acord',
        dataIndex: 'gdpr_agreement',
        key: 'gdpr_agreement',
        render: (text) => (text ? 'Da' : 'Nu'), // Convert boolean to Yes/No
    },
    {
        title: 'Newsletter Subscriptie',
        dataIndex: 'newsletter_subscription',
        key: 'newsletter_subscription',
        render: (text) => (text ? 'Da' : 'Nu'), // Convert boolean to Yes/No
    },
    {
        title: 'Domeniu de interes',
        dataIndex: 'domains', // Changed to match API response
        key: 'domains',
        render: (domains) => domains && domains.join(', '), // Join array into a string
    },
    {
        title: 'Alt domeniu',
        dataIndex: 'other_domains', // Changed to match API response
        key: 'other_domains',
    },
    {
      title: 'Data completare',
      dataIndex: 'created_at',
      render: (created_at) => moment(created_at).format('YYYY-MM-DD')
    }
];

const ProgramAnswers = () => {
    const navigate = useNavigate();
    const [answers, setAnswers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        answersService.getProgramAnswers()
            .then(response => {
                if (response.status === 401) {
                    navigate("/login");
                } else {
                    setAnswers(response.data.program_applicants);
                    setPagination(response.data.pagination);
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    navigate("/login");
                } else {
                    console.error('Error fetching data:', error);
                }
            });
    }, [navigate]);
    const dataSource = Object.keys(answers).map((answer) => ({
        answers,
        ...answers[answer],
      }));

      const handlePageClick = (data) => {
          answersService.getProgramAnswers(data.selected + 1)
              .then(response => {
                  if (response.status === 401) {
                      navigate("/login");
                  } else {
                      setAnswers(response.data.program_applicants);
                      setPagination(response.data.pagination);
                  }
              })
              .catch(error => {
                  if (error.response && error.response.status === 401) {
                      navigate("/login");
                  } else {
                      console.error('Error fetching data:', error);
                  }
              });
      };

    return (
        <Layout className='standard'>
            <Header>
                <div className="logo" />
                <Menu theme="dark" mode="horizontal">
                    <Menu.Item key="1">
                        <Link to="/raspunsuri-program">Raspunsuri program</Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <Link to="/raspunsuri-summerwell">Raspunsuri Summerwell</Link>
                    </Menu.Item>
                </Menu>
            </Header>

            <Content style={{ padding: '0 50px', marginTop: 64 }}>
                <div style={{ padding: 24, minHeight: 280 }}>
                    <Table dataSource={dataSource} columns={columns} rowKey="id" pagination={false}  title={() => (
                        <div>
                            <h2>Răspunsuri Chestionar </h2>
                        </div>
                    )}/>
                    {pagination && pagination.last_page && (
                      <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={pagination.last_page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                      />
                    )}
                </div>
            </Content>
        </Layout>
    );
};

export default ProgramAnswers;
